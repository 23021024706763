<template>
    <div v-if="item && item.id" class="zt-page-content">
        <div class="flex flex-v" style="height:100%;overflow-y:hidden;">
            <div class="flex-1" style="overflow-y:auto;">
                <div class="content">
                    <div class="zt-block">
                        <div class="content-top flex">
                            <div v-if="item.photo_render && item.photo_render.length>0" class="img-carousel">
                                <ItemImages :imgs="item.photo_render" :preUrl="imgUrl" width="400px"></ItemImages>
                            </div>
                            <div class="detail flex-1">
                                <h3 class="item-name ellipsis2">{{item.name}}</h3>
                                <div class="item-series flex">
                                    <div>{{item.style.name}}</div>
                                    <div>{{item.cat.name}}</div>
                                </div>
                                <div v-if="item.design_idea" class="design-idea">
                                    <div>设计理念:</div>
                                    <div class="font14-grey1">{{item.design_idea}}</div>
                                </div>
                                <div class="item-look flex">
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>累计点击</div>
                                    </div>
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>累计下载</div>
                                    </div>
                                    <!-- <div class="look-item">
                                        <div>4</div>
                                        <div>商品询问数</div>
                                    </div> -->
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>已合作的订单</div>
                                    </div>
                                    <div class="look-item">
                                        <div>--</div>
                                        <div>第三方平台上架</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <compWrap v-if="item.design_comp" :design_comp="item.design_comp" :type="'_fchz'"></compWrap>
                    <div style="margin-bottom: 0;" class="zt-block flex">
                        <div class="flex-1" style="margin-left:70px;padding:24px 0;">
                            <div class="tab_menu flex">
                                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="content-box flex">
                                <!-- <div style="height:350px;padding:50px 40px;" class="box-steps">
                                    <el-steps :active="item.sign_stat" direction="vertical">
                                        <el-step title="标高图" icon="iconfont icon-zu14"></el-step>
                                        <el-step title="素材图" icon="iconfont icon-zu14"></el-step>
                                    </el-steps>
                                </div> -->
                                <div class="pic-wrap-left">
                                    <div class="active">
                                        <i></i><span>标高图</span>
                                    </div>
                                    <div class="line"></div>
                                    <div>
                                        <i></i><span>素材图</span>
                                    </div>
                                </div>
                                <div v-if="tabIndex==0">
                                    <div class="content-img">
                                        <div class="ellipsis" style="font-size:22px;color:#808080;padding-top:20px;"
                                            >{{item.name}}</div>
                                        <div v-if="item.dimension" class="size">
                                            <label v-for="(size,idx) in item.dimension" :key="idx">{{size}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                        </div>
                                        <div style="font-size:18px;color:#808080;padding:20px 0 10px 0;">标高图</div>
                                        <div v-if="item.photo_size && item.photo_size.length>0">
                                            <div v-for="(item,idx) in item.photo_size" :key="idx">
                                                <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                            </div>
                                        </div>
                                        <div v-else>
                                            <img src="../../../assets/images/desgin/zwt.png" alt="">
                                        </div>
                                        <div style="font-size:18px;color:#808080;padding:30px 0 20px 0;">素材图</div>
                                        <div v-if="item.photo_story && item.photo_story.length>0">
                                            <div v-for="(item,index) in item.photo_story" :key="index">
                                                <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                            </div>
                                        </div>
                                        <div v-else>
                                            <img src="../../../assets/images/desgin/zwt.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tabIndex>0">
                                    <div v-for="(item,idx) in item.accessory" :key="idx">
                                        <div v-if="tabIndex===(idx+1)" class="content-img">
                                            <div class="ellipsis" style="font-size:22px;color:#808080;padding-top:20px;"
                                                >{{item.name}}</div>
                                            <div v-if="item.dimension" class="size">
                                                <label v-for="(size,idx) in item.dimension" :key="idx">{{size}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                            </div>
                                            <div style="font-size:18px;color:#808080;padding:20px 0 10px 0;">标高图</div>
                                            <div v-if="item.photo_size && item.photo_size.length>0">
                                                <div v-for="(item,idx) in item.photo_size" :key="idx">
                                                    <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                                </div>
                                            </div>
                                            <div v-else>
                                                <img src="../../../assets/images/desgin/zwt.png" alt="">
                                            </div>
                                            <div style="font-size:18px;color:#808080;padding:30px 0 20px 0;">素材图</div>
                                            <div v-if="item.photo_story && item.photo_story.length>0">
                                                <div v-for="(item,index) in item.photo_story" :key="index">
                                                    <img :src="(imgUrl || '')+item+'!width_800px'" alt="">
                                                </div>
                                            </div>
                                            <div v-else>
                                                <img src="../../../assets/images/desgin/zwt.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="item &&item.photo_cad" class="content-cad flex">
                                <div>设计CAD文件：</div>
                                <div class="flex-1 flex">
                                    <img src="../../../assets/images/desgin/icon_cad.png" alt="">
                                    <div style="padding-top:50px" class="font14-grey1">{{item.photo_cad.length}}份</div>
                                    <div style="padding-top:35px;margin-left: 20px;">
                                        <!-- <el-button v-if="item && item.stat==1" @click="download()" size="small" type="primary" plain>下载</el-button> -->
                                        <div v-if="item.photo_cad.length>0" style="font-size:14px;color:#F66F6A;padding-top:15px;">合作后可下载</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div style="width:310px;margin: 24px;">
                            <div class="goods-title">同系列作品</div>
                            <div class="goods-list">
                                <div v-for="(item,idx) in items" :key="idx" class="item">
                                    <div @click="gotoDetail(item.id)" style="width:230px;height:230px;margin-right:40px;cursor:pointer;">
                                        <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                                        :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}"></div>
                                    </div>
                                    <div class="detail">
                                        <div @click="gotoDetail(item.id)" class="item-name ellipsis">{{item.name}}</div>
                                        <div class="item-series flex">
                                            <div>{{item.style.name}}</div>
                                            <div>{{item.cat.name}}</div>
                                        </div>
                                        <div style="margin-bottom:8px;" class="flex">
                                            <div class="item-price">{{10000-item.price_percent | percentFilt}}%</div>
                                            <div style="line-height:28px;margin-left:4px;">分成比例</div>
                                        </div>
                                        <div class="item-coop flex">
                                            <div class="item-progress">
                                                <div :style="{width:item.factory_count+'0%'}"></div>
                                                <div style="position:absolute;top:0;left:0;">已合作:{{item.factory_count}}</div>
                                            </div>
                                            <div style="font-size:12px;color:#016FFF;">剩余名额：{{10-item.factory_count}}</div>
                                        </div>
                                        <div style="font-size:12px;">已有{{item.design_market_n_visited}}人查看</div>
                    
                                    </div>
                                </div>
                            </div>
        
                        </div>
        
                    </div>
        
                </div>

            </div>
            <div class="fixed-bottom">
                <div class="flex">
                    <div style="font-size:32px;color:#EE1818;" class="flex-1">分成比例：{{10000-item.price_percent | percentFilt}}%</div>
                    <div class="flex">
                        <!-- item && item.sign_stat<=1 --> 
                        <div v-if="item && item.trans_state==1">
                            <el-button @click="gotoSign(item)" type="primary">我要合作</el-button>
                        </div>
                        <!-- <div v-if="item && item.stat==1">
                            <el-button @click="stopCoop(item)" type="danger" plain>终止合作</el-button>
                        </div> -->
                        <!-- <div v-if="item && item.stat==1" style="font-size:24px;color:#2BBAAE;margin-left:30px;">合作中</div> -->
                        <div v-if="item && item.trans_state==2" style="font-size:24px;color:#F66F6A;">合作中</div>
                        <div v-if="item && item.trans_state==3" style="font-size:24px;color:#F66F6A;">合作已终止</div>
                        <div v-if="item && item.factory_list && item.factory_list.ing && item.factory_list.ing.length==10" 
                            style="font-size:24px;color:#F66F6A;">已爆满</div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import { IMG_URL_PRE } from '@/config';
    import { marketPercentList,marketPercentId,factoryPercentId } from '@/service/factory';
    import ItemImages from '@/components/ItemImages.vue';
    import compWrap from '../Zysp/compWrap.vue';
    export default {
        components: {
            ItemImages,
            compWrap
        },
        props:{},
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                popVisible: false,
                popTitle: null,
                tabList:[
                    {name:"主作品"},
                ],
                tabIndex:0,
                item:null,
                queryOpt: {
                    pagesize: 3,
                    page: 1,
                },
                total:0,
                items: [],
            }
        },
        watch: {
            $route(to,from){
                this.item = null;
                this.data();
                this.refreshItems();
            }
        },
        created:function() {
            this.data();
            this.refreshItems();
        },
        methods: {
            data() {
                marketPercentId(this.$route.params.id).then(rst=>{
                    if (rst) {
                        this.item=rst;
                        if (this.item.accessory && this.item.accessory.length>0) {
                            this.item.accessory.forEach(e => {
                                this.tabList.push({name:e.name});
                            });
                        }
                        this.item.trans_state = 1;
                        if (this.item.factory_list && this.item.factory_list.ing.length>0) {
                            this.item.factory_list.ing.forEach(e => {
                                if (e.id == this.$store.state.loginUser.comp.id) {
                                    this.item.trans_state = 2;
                                }
                            });
                        }
                        if (this.item.factory_list && this.item.factory_list.end.length>0) {
                            this.item.factory_list.end.forEach(e => {
                                if (e.id == this.$store.state.loginUser.comp.id) {
                                    this.item.trans_state = 3;
                                }
                            });
                        }
                    }
                    console.log(this.item);
                });
            },
            refreshItems() {
                console.log('refreshItems');
                marketPercentList(this.queryOpt).then(rst => {
                    if (rst.list && rst.list.length>0) {
                        this.total=rst.total;
                        this.items = rst.list;
                    }
                    console.log(this.items);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            goto(path){
                this.$router.push(path);
            },
            gotoDetail(id) {
                this.$router.push("/factory/gmsj/fchz/detail/"+id);
            },
            tab(index) { // 选项卡
                this.tabIndex = index;
                console.log(index);
            },
            // 合作
            gotoSign(item) {
                this.$router.push("/factory/hzzpQuote/"+item.id+"?market=0");
                
                // let obj = {
                //     name:item.name,
                //     cat:item.cat,
                //     set:item.set,
                // }
                // sessionStorage.setItem("quoteInfo",JSON.stringify(obj));
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped src="../detail.css"></style>
<style scoped>
    .content {
        /* width: 80%; */
        min-width: 1360px;
        margin: 0 auto;
        padding:0 30px;
        background: transparent;
    }
    .content-top {
        padding: 64px 84px 45px;
    }
    .content-top .detail {
        margin-left: 45px;
        position: relative;
    }
    .content-top .item-name {
        font-size: 24px;
        font-weight: bold;
        color: #292929;
        line-height: 28px;
        margin: 12px 0;
    }
    .content-top .item-series {
        font-size: 14px;
        color: #808080;
        line-height: 18px;
        margin-bottom: 54px;
    }
    .content-top .item-series>div {
        padding: 0 24px;
        border-right: 1px solid #CCCCCC;
    }
    .content-top .item-series>div:first-child {
        padding-left: 0;
    }
    .content-top .item-series>div:last-child {
        padding-right: 0;
        border-right: none;
    }
    .content-top div.design-idea {
        max-width: 650px;
        padding: 8px 16px 16px 16px;
        background: rgba(43,186,174,0.05);
    }
    .content-top .design-idea>div:first-child {
        font-size: 16px;
        color: #4D4D4D;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .content-top .item-look {
        position: absolute;
        left: 0;
        bottom: 87px;
    }
    .content-top .item-look .look-item {
        min-width: 128px;
        height: 70px;
        text-align: center;
        padding: 8px 0;
        border-radius: 4px;
        background:rgba(255,145,0,0.1);
        margin-right: 20px;
    }
    .content-top .look-item>div:first-child {
        font-size: 24px;
        color: #F66F6A;
        line-height: 33px;
    }
    .content-top .look-item>div:last-child {
        font-size: 16px;
        color: #4D4D4D;
        line-height: 22px;
    }
    /* -------------工厂信息-------------- */
    .facList {
        padding: 20px 30px;
        border:1px solid rgba(204,204,204,1);
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
        border-radius:6px;
    }
    .facList-left {
        /* padding-right: 60px;
        border-right: 1px solid #CCCCCC; */
    }
    .fac-label {
        max-width: 400px;
    }
    .fac-label>div {
        font-size: 14px;
        color: #016FFF;
        background:rgba(1,111,255,0.2);
        border-radius: 12px;
        padding: 0 16px;
        height: 24px;
        line-height: 24px;
        margin-right: 16px;
        margin-bottom: 6px;
    }
    .fac-label>div:nth-child(4n) {
        margin-right: 0;
    }
    .fac-label>div:nth-child(2),.fac-label>div:nth-child(7) {
        background:rgba(43,186,174,0.2);
        color: #2BBAAE;
    }
    .fac-label>div:nth-child(3),.fac-label>div:nth-child(8) {
        background:rgba(230,162,60,0.2);
        color: #E6A23C;
    }
    .fac-label>div:nth-child(4),.fac-label>div:nth-child(5) {
        background:rgba(246,111,106,0.2);
        color: #F66F6A;
    }

    /* ----------弹框---------- */
    div.container>>> .el-dialog__body {
        background: #F5F5F5;
    }
    div.container>>> .el-dialog {
        min-width: 600px;
    }
</style>
<style scoped>
    /* 右边同系列作品 */
    .goods-title {
        font-size:20px;
        color:#4D4D4D;
        line-height:90px;
        background: #E6E6E6;
        text-align: center;
        margin-bottom:5px;
    }
    .goods-list {
        background: #F5F5F5;
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
        overflow: hidden;
        border-radius: 2px;
    }
    .goods-list .item{
        width: 230px;
        overflow: hidden;
        border:1px solid rgba(204,204,204,1);
        margin: 30px auto;
        background: #fff;
    }
    .goods-list .item .detail {
        padding:8px 13px;
        position: relative;
        font-size: 14px;
        color: #808080;
    }
    .goods-list .item .item-name {
        font-size: 16px;
        font-weight: bold;
        color: rgba(41, 41, 41, 1);
        line-height:22px;
        margin-bottom: 4px;
    }
    .goods-list .item-series {
        font-size: 14px;
        color: #808080;
        line-height: 18px;
        margin-bottom: 4px;
    }
    .goods-list .item-series>div {
        padding: 0 10px;
        border-right: 1px solid #CCCCCC;
    }
    .goods-list .item-series>div:first-child {
        padding-left: 0;
    }
    .goods-list .item-series>div:last-child {
        padding-right: 0;
        border-right: none;
    }
    .goods-list .item-price {
        font-size: 20px;
        color: #EE1818;
        line-height: 28px;
    }
    .goods-list .item-progress {
        width: 91px;
        height: 20px;
        line-height: 20px;
        background: #E6E6E6;
        font-size: 12px;
        color: #fff;
        position: relative;
        margin-right: 10px;
        margin-bottom: 8px;
    }
    .goods-list .item-progress>div:first-child {
        height: 20px;
        background: #E6A23C;
    }
    /* ------底部------ */
    .fixed-bottom {
        width: 100%;
        min-width: 1360px;
        height: 108px;
        background:rgba(230,230,230,1);
    }
    .fixed-bottom>div {
        padding: 30px 0;
        width: 1260px;
        margin: 0 auto;
    }
</style>